import React from "react";
import { Link } from "react-router-dom";
import "../../styles/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="footer-main">
      <footer className="footer">
        <div className="footer-section">
          <h1>City Cart</h1>

          <p>order your grocery and foods now</p>
          <p>+91 7817874900, 7817875900</p>
          <Link to="mailto:support@citycart.co.in" className="linkk" style={{textDecoration:"none", color:"white"}}>support@citycart.co.in</Link>
        </div>
        <div className="footer-section">
          <h4>Links</h4>

          <div>
            <Link to="/about" className="linkk"style={{textDecoration:"none", color:"white"}}>About us</Link>
          </div>
          <div>
            <Link to="/contact" className="linkk"style={{textDecoration:"none", color:"white"}}>Contact us</Link>
          </div>
        </div>
        <div className="footer-section">
          <h4>Follow Us On:</h4>
          <div className="social-icons">
            <Link
              to="https://www.facebook.com/citycart78?mibextid=qi2Omg&rdid=lSpN5ji8TTKAmOXc&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FhittiJpFSPXVjieR%2F%3Fmibextid%3Dqi2Omg"
              target="_blank"
              rel="noopener noreferrer"
              className="linkk"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </Link>
            {/* <Link to="https://twitter.com/yourpage" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></Link> */}
            <Link
              to="https://www.instagram.com/citycart38/"
              target="_blank"
              rel="noopener noreferrer"
              className="linkk"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
          </div>
        </div>
        <div className="footer-section">
          <h4>Address:</h4>
          <p>Chandausi (UP), India, 244412</p>
        </div>
      </footer>
      <p className="all-rights">All Rights Reserved &copy; citycart</p>
    </div>
  );
};

export default Footer;
