import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
        <div className="container-fluid" style={{ marginTop: "2px" }}>
          <div
            className="collapse navbar-collapse"
            id="navbarTogglerDemo01"
            style={{ marginTop: "5px" }}
          >
            <Link to="/" className="navbar-brand">
              <img
                src="/images/logoo.jpeg"
                alt="logo"
                className="w-50"
                style={{ width: "64px", borderRadius: "40%" }}
              />
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
