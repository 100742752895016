import React, { useState } from "react";
// import { Link,  } from "react-router-dom";

import Layout from "../../components/Layout/Layout";

import "../../styles/Homepage.css";

const HomePage = () => {
  const [downloading, setDownloading] = useState(null);

  const handleDownload = (file) => {
    setDownloading(file);
    setTimeout(() => {
      setDownloading(null);
      window.location.href = `/files/${file}.apk`;
    }, 2000); // Simulate a 2-second delay
  };

  return (
    <Layout title={"city cart download app "}>
      <div className="grid-background">
        <div className="header-book">
          <h1> Want to order your favorite <span className="gradient-text">Foods</span>?</h1>
          <h2>OR</h2>
          <h1>your daily fresh  <span className="gradient-text">Groceries</span> on your fingertips</h1>
        </div>
        <div className="body-book">
          <p>download our citycart app and order now</p>
        </div>

        <div className="button-book">
          <button
            onClick={() => handleDownload("user")}
            disabled={downloading === "user"}
          >
            {downloading === "user" ? "Downloading..." : "Shop Now"}
          </button>
          <button
            onClick={() => handleDownload("vendor")}
            disabled={downloading === "vendor"}
          >
            {downloading === "vendor" ? "Downloading..." : "Manage Store"}
          </button>
        </div>
      </div>
      <div className="plans-main">
        <div className="plans">
          <div className="plans-box1">
            <h2>Dwnload your</h2>
            <h1>CityCart</h1>
            <h2>app and </h2>
            <h1>order</h1>
          </div>
          <div className="plans-box2">
            <img
              src="/images/sh1.svg"
              alt="plans"
              className=""
              style={{ width: "70%" }}
            />
          </div>
        </div>
        <button
          onClick={() => handleDownload("user")}
          disabled={downloading === "user"}
        >
          {downloading === "user" ? "Downloading..." : "Download User APP"}
        </button>
        {downloading === "user" && (
          <p className="download-message">
            Please wait, your download will start shortly...
          </p>
        )}
      </div>

      <div className="like">
        <div className="like-head">
          <span>Why you'll Love us?</span>
        </div>
        <p>
          CityCart is your trusted partner in making grocery shopping and
          selling a breeze. Our mission is to connect local stores with
          customers, offering a platform where you can find everything you need,
          delivered with just one click. We strive to support local businesses
          and provide our users with the best shopping experience possible.
        </p>
      </div>

      <div className="plans-main">
        <div className="plans">
          <div className="plans-box1">
            <h2>Dwnload our</h2>
            <h1>CityCart</h1>
            <h2>vendor app </h2>
            <h1>now</h1>
          </div>
          <div className="plans-box2">
            <img
              src="/images/sh3.svg"
              alt="plans"
              className=""
              style={{ width: "70%" }}
            />
          </div>
        </div>
        <button
          onClick={() => handleDownload("vendor")}
          disabled={downloading === "vendor"}
        >
          {downloading === "vendor" ? "Downloading..." : "Download Vendor APP"}
        </button>
        {downloading === "vendor" && (
          <p className="download-message">
            Please wait, your download will start shortly...
          </p>
        )}
      </div>
    </Layout>
  );
};

export default HomePage;
